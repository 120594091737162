import * as React from "react";
import { CSSProperties, FC, useEffect, useState, useCallback } from "react";
import { useRouter, usePathname, useSearchParams } from "next/navigation";
import { Toaster } from "react-hot-toast";
import { Button, Col, Spinner } from "react-bootstrap";
import { RunetEvent, User } from "@runetid/js-sdk";

type IRegisterOnEventProps = {
  className?: string;
  style?: CSSProperties;
  event: RunetEvent;
  user: User | null;
};

const checkUserRegistration = async (eventId: number | undefined, runetId: number) => {
  const response = await fetch(`/api/event/simpleRegister/checkRegister?event_id=${eventId}&runet_id=${runetId}`);
  const body = await response.json();

  return body.isRegistered;
};

const registerUserForEvent = async (eventId: number, userId: number, roleId: number) => {
  const response = await fetch("/api/event/simpleRegister", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ event_id: eventId, user_id: userId, role_id: roleId }),
  });
  const body = await response.json();

  return body?.data?.id;
};

export const SimpleReg: FC<IRegisterOnEventProps> = ({ event, user }) => {
  const [registrationStatus, setRegistrationStatus] = useState({
    registered: false,
    loading: true
  });
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    const fetchRegistrationStatus = async () => {
      if (!user) return setRegistrationStatus({ registered: false, loading: false });

      try {
        const isRegistered = await checkUserRegistration(event.id, user.runet_id);
        setRegistrationStatus({
          registered: isRegistered,
          loading: false
        });
      } catch (error) {
        console.error("Ошибка при проверке регистрации:", error);
        setRegistrationStatus({ registered: false, loading: false});
      }
    };

    fetchRegistrationStatus();
  }, [registrationStatus.registered]);

  const handleClick = useCallback(async () => {
    if (!user) {
      const path = `${pathname}?${searchParams.toString()}`;
      document.cookie = `redirect=${path}; path=/;`;
      router.push("/auth/login");
      return;
    }

    try {
      const registrationId = await registerUserForEvent(event.id, user.id, event.default_role_id);
      if (registrationId) {
        setRegistrationStatus((prev) => ({ ...prev, registered: true }));
      }
    } catch (error) {
      console.error(error);
    }
  }, [registrationStatus.registered]);

  return (
    <Col xs={{ span: 12, order: 1 }} md={{ span: "auto", order: 1 }}
         lg={{ span: 12, order: 1 }} style={{ minHeight: "60px" }}>
      <Toaster />
      {registrationStatus.loading ? (
        <div className={"d-flex align-items-center justify-content-center"}>
          <Spinner animation="border" size="sm" />
        </div>
      ) : registrationStatus.registered ? (
        <>Вы зарегистрированы</>
      ) : (
        <Button onClick={handleClick} className="sEventReg__btn" variant="light"
                style={{ position: "relative" }}>
          Зарегистрироваться
        </Button>
      )}
    </Col>
  );
};
